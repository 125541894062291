import { get, writable } from 'svelte/store';
export const activeOverlays = writable([]);
function runOverlay(params) {
    return new Promise(resolve => {
        activeOverlays.update($overlays => {
            $overlays = [...$overlays, Object.assign(Object.assign({}, params), { onDone: resolve })];
            return $overlays;
        });
    });
}
function removeOverlay(id, result) {
    activeOverlays.update($overlays => {
        const newOverlays = [];
        for (const overlay of $overlays) {
            if (overlay.id !== id)
                newOverlays.push(overlay);
            else
                overlay.onDone(result);
        }
        return newOverlays;
    });
}
let counter = 0;
export function registerOverlay(component) {
    const id = `overlay-id-${counter++}`;
    async function run(params) {
        return await runOverlay({ component, params, id });
    }
    function getParams() {
        const overlays = get(activeOverlays);
        for (const overlay of overlays) {
            if (overlay.id === id)
                return overlay.params;
        }
        throw new Error('overlay is not active');
    }
    function close(result) {
        removeOverlay(id, result);
    }
    return { component, run, getParams, close };
}
