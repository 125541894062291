// deno-lint-ignore-file
// This is a generated file.  Do not edit.
export function uuid() {
    let d = Date.now();
    return `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, c => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == `x` ? r : (r & 0x3) | 0x8).toString(16);
    });
}
export function generateClientId(prefix = 'client-id-') {
    return `${prefix}${uuid()}`;
}
function makeStorable(value) {
    const subscribers = [];
    function get() {
        return value;
    }
    function set(newVal) {
        value = newVal;
        subscribers.forEach(listener => listener(value, false));
    }
    function subscribe(listener) {
        listener(value, true);
        subscribers.push(listener);
        return () => {
            const index = subscribers.indexOf(listener);
            if (index === -1)
                return; // already unsubscribed
            subscribers.splice(index, 1);
        };
    }
    function getNumberOfSubscribers() {
        return subscribers.length;
    }
    return {
        get,
        set,
        subscribe,
        getNumberOfSubscribers,
    };
}
const preOpenedMethods = [];
const defaultOnSubscriptionHandler = (methodPath, subscriptionCount) => {
    const methodPathIndex = preOpenedMethods.indexOf(methodPath);
    const exists = methodPathIndex !== -1;
    if (subscriptionCount > 0 && !exists)
        preOpenedMethods.push(methodPath);
    else if (subscriptionCount === 0 && exists)
        preOpenedMethods.splice(methodPathIndex, 1);
};
let onSubscription = defaultOnSubscriptionHandler;
function makeCustomStorable(methodPath, value) {
    const { get, getNumberOfSubscribers, set, subscribe } = makeStorable(value);
    return {
        readable: {
            get,
            subscribe(fn) {
                const unsubscribe = subscribe(fn);
                onSubscription(methodPath, getNumberOfSubscribers());
                return () => {
                    unsubscribe();
                    onSubscription(methodPath, getNumberOfSubscribers());
                };
            },
        },
        set,
        getNumberOfSubscribers,
        isArray: Array.isArray(value),
    };
}
let apiUrl = null;
let wsUrl = null;
let clientId = null;
export const user = makeStorable({
    clientId: 'not-yet-allocated',
    isAdmin: false,
    isGuest: true,
    isReal: false,
});
export const online = makeStorable(false);
const heartbeatNumber = makeStorable(0);
export function injectHeartbeat() {
    heartbeatNumber.set(heartbeatNumber.get() + 1);
}
// Create a heartbeat every 10 seconds
setInterval(injectHeartbeat, 10 * 1000);
/** Bare connect takes in the stores to be synced as an object { 'controllerName/methodName': storable } */
function bareConnect(storables) {
    return new Promise(resolve => {
        if (!clientId || !wsUrl)
            throw new Error('invalid params passed to connect');
        const unsubscribers = [];
        const methodIsObserving = {};
        const url = `${wsUrl}/connection`;
        console.log(`[socket] connecting to ${url}...`);
        let didConnect = false;
        const websocket = new WebSocket(`${url}?clientId=${clientId}`);
        websocket.onclose = () => {
            onSubscription = defaultOnSubscriptionHandler;
            unsubscribers.forEach(fn => fn());
            online.set(false);
            console.log('[socket] disconnected, waiting for three seconds before reconnecting...');
            setTimeout(() => {
                bareConnect(storables).then(() => {
                    if (!didConnect)
                        resolve();
                });
            }, 3000);
        };
        websocket.onopen = () => {
            console.log('[socket] connected');
            online.set(true);
            didConnect = true;
            resolve();
            for (const methodPath in storables)
                methodIsObserving[methodPath] = preOpenedMethods.indexOf(methodPath) !== -1;
            for (const methodPath in methodIsObserving) {
                const observing = methodIsObserving[methodPath];
                if (observing)
                    websocket.send(JSON.stringify({ $: 'add-observation', methodPath }));
            }
            onSubscription = (methodPath, numberOfSubscribers) => {
                const isObserving = methodIsObserving[methodPath];
                const shouldObserve = numberOfSubscribers > 0;
                if (isObserving && shouldObserve)
                    return;
                if (!isObserving && !shouldObserve)
                    return;
                if (isObserving && !shouldObserve) {
                    // set the observation to it's default value (null or [], depending on the tense) when subscribers are removed to conserve memory
                    storables[methodPath].set(storables[methodPath].isArray ? [] : null);
                    methodIsObserving[methodPath] = false;
                    return websocket.send(JSON.stringify({ $: 'remove-observation', methodPath }));
                }
                if (!isObserving && shouldObserve) {
                    methodIsObserving[methodPath] = true;
                    return websocket.send(JSON.stringify({ $: 'add-observation', methodPath }));
                }
            };
            unsubscribers.push(heartbeatNumber.subscribe(() => {
                websocket.send(JSON.stringify({ $: 'heartbeat' }));
            }));
        };
        websocket.onmessage = ({ data }) => {
            const message = JSON.parse(data);
            // if .set(document) was called on the model
            if (message.$ === 'item-set') {
                const methodPath = message.methodPath;
                const storable = storables[methodPath];
                storable.set(message.data);
            }
            // if .insert(document) was called on the model
            else if (message.$ === 'item-insert') {
                const methodPath = message.methodPath;
                const storable = storables[methodPath];
                const currentValue = storable.readable.get();
                if (!Array.isArray(currentValue))
                    throw new Error('cannot perform an insert on a value that is not an array');
                currentValue.push(message.data);
                storable.set(currentValue);
            }
            // if .update(document) was called on the model
            else if (message.$ === 'item-update') {
                const methodPath = message.methodPath;
                const storable = storables[methodPath];
                const currentValue = storable.readable.get();
                if (!Array.isArray(currentValue))
                    throw new Error('cannot perform an insert on a value that is not an array');
                const indexOfItemToUpdate = currentValue.findIndex(item => item[message.index] === message.data[message.index]);
                if (indexOfItemToUpdate === -1)
                    throw new Error('cannot find item that was supposed to be updated');
                currentValue[indexOfItemToUpdate] = message.data;
                storable.set(currentValue);
            }
            // if .remove(documentId) was called on the model
            else if (message.$ === 'item-remove') {
                const methodPath = message.methodPath;
                const storable = storables[methodPath];
                const currentValue = storable.readable.get();
                if (!Array.isArray(currentValue))
                    throw new Error('cannot perform an insert on a value that is not an array');
                const indexOfItemToRemove = currentValue.findIndex(item => item[message.index] === message.id);
                if (indexOfItemToRemove === -1)
                    throw new Error('cannot find item that was supposed to be updated');
                currentValue.splice(indexOfItemToRemove, 1);
                storable.set(currentValue);
            }
            else if (message.$ === 'auth-change') {
                if (!clientId)
                    throw new Error('unexpected outcome in generated glue code');
                if (message.changeTo === 'guest')
                    user.set({ clientId, isAdmin: false, isGuest: true, isReal: false });
                else if (message.changeTo === 'real')
                    user.set({ clientId, isAdmin: false, isGuest: false, isReal: true, userId: message.userId });
                else if (message.changeTo === 'admin')
                    user.set({ clientId, isAdmin: true, isGuest: false, isReal: false });
            }
        };
    });
}
async function conventionalMethod(params) {
    if (!apiUrl || !clientId)
        throw new Error('connect must be called before any methods are called');
    const returnType = params.returnType || 'string';
    const headers = new Headers();
    headers.append('Client-Id', clientId);
    return await fetch(`${apiUrl}/call/${params.controllerName}/${params.methodName}`, {
        body: JSON.stringify({ params: params.params }),
        method: 'POST',
        headers,
    }).then(async (res) => {
        if (!res.ok)
            throw new Error(await res.text());
        if (returnType === 'binary')
            return new Uint8Array(await res.arrayBuffer());
        if (returnType === 'json')
            return (await res.json()).data;
        // do nothing for returnType === 'void'
    });
}
const store_user_$user = makeCustomStorable('user/$user', null);
const store_user_$incomeTypes = makeCustomStorable('user/$incomeTypes', []);
const store_user_$unallocatedExpenses = makeCustomStorable('user/$unallocatedExpenses', []);
const store_user_$pinnedEnvelopes = makeCustomStorable('user/$pinnedEnvelopes', []);
const store_envelopes_$envelopes = makeCustomStorable('envelopes/$envelopes', []);
const store_locations_$locations = makeCustomStorable('locations/$locations', []);
export async function connect(params) {
    const secure = params.forceSecure || (location === null || location === void 0 ? void 0 : location.protocol) === 'https:';
    clientId = params.clientId;
    apiUrl = `${secure ? 'https' : 'http'}://${params.host}`;
    wsUrl = `${secure ? 'wss' : 'ws'}://${params.host}`;
    user.set({ clientId, isAdmin: false, isGuest: true, isReal: false });
    await bareConnect({
        'user/$user': store_user_$user,
        'user/$incomeTypes': store_user_$incomeTypes,
        'user/$unallocatedExpenses': store_user_$unallocatedExpenses,
        'user/$pinnedEnvelopes': store_user_$pinnedEnvelopes,
        'envelopes/$envelopes': store_envelopes_$envelopes,
        'locations/$locations': store_locations_$locations,
    });
}
export const controllers = {
    transaction: {
        async getLatestTransactions(params) {
            return await conventionalMethod({
                controllerName: 'transaction',
                methodName: 'getLatestTransactions',
                params: params,
                returnType: 'json',
            });
        },
        async getTransaction(params) {
            return await conventionalMethod({
                controllerName: 'transaction',
                methodName: 'getTransaction',
                params: params,
                returnType: 'json',
            });
        },
        async createFluctuateTransaction(params) {
            return await conventionalMethod({
                controllerName: 'transaction',
                methodName: 'createFluctuateTransaction',
                params: params,
                returnType: 'void',
            });
        },
        async createTransferTransaction(params) {
            return await conventionalMethod({
                controllerName: 'transaction',
                methodName: 'createTransferTransaction',
                params: params,
                returnType: 'void',
            });
        },
        async nameTransaction(params) {
            return await conventionalMethod({
                controllerName: 'transaction',
                methodName: 'nameTransaction',
                params: params,
                returnType: 'void',
            });
        },
        async allocateExpenseTransaction(params) {
            return await conventionalMethod({
                controllerName: 'transaction',
                methodName: 'allocateExpenseTransaction',
                params: params,
                returnType: 'void',
            });
        },
    },
    authenticate: {
        async sendConfirmationEmail(params) {
            return await conventionalMethod({
                controllerName: 'authenticate',
                methodName: 'sendConfirmationEmail',
                params: params,
                returnType: 'void',
            });
        },
        async verifyOtp(params) {
            return await conventionalMethod({
                controllerName: 'authenticate',
                methodName: 'verifyOtp',
                params: params,
                returnType: 'void',
            });
        },
        async logout() {
            return await conventionalMethod({
                controllerName: 'authenticate',
                methodName: 'logout',
                params: null,
                returnType: 'void',
            });
        },
    },
    user: {
        $user: store_user_$user.readable,
        $incomeTypes: store_user_$incomeTypes.readable,
        $unallocatedExpenses: store_user_$unallocatedExpenses.readable,
        $pinnedEnvelopes: store_user_$pinnedEnvelopes.readable,
        async createIncomeType(params) {
            return await conventionalMethod({
                controllerName: 'user',
                methodName: 'createIncomeType',
                params: params,
                returnType: 'void',
            });
        },
        async editIncomeType(params) {
            return await conventionalMethod({
                controllerName: 'user',
                methodName: 'editIncomeType',
                params: params,
                returnType: 'void',
            });
        },
        async removeIncomeType(params) {
            return await conventionalMethod({
                controllerName: 'user',
                methodName: 'removeIncomeType',
                params: params,
                returnType: 'void',
            });
        },
        async setDefaultInputType(params) {
            return await conventionalMethod({
                controllerName: 'user',
                methodName: 'setDefaultInputType',
                params: params,
                returnType: 'void',
            });
        },
        async getDefaultInputType() {
            return await conventionalMethod({
                controllerName: 'user',
                methodName: 'getDefaultInputType',
                params: null,
                returnType: 'json',
            });
        },
        async getAccountName(params) {
            return await conventionalMethod({
                controllerName: 'user',
                methodName: 'getAccountName',
                params: params,
                returnType: 'json',
            });
        },
    },
    envelopes: {
        $envelopes: store_envelopes_$envelopes.readable,
        async createNewEnvelope(params) {
            return await conventionalMethod({
                controllerName: 'envelopes',
                methodName: 'createNewEnvelope',
                params: params,
                returnType: 'json',
            });
        },
        async editEnvelopeStyles(params) {
            return await conventionalMethod({
                controllerName: 'envelopes',
                methodName: 'editEnvelopeStyles',
                params: params,
                returnType: 'void',
            });
        },
        async removeEnvelope(params) {
            return await conventionalMethod({
                controllerName: 'envelopes',
                methodName: 'removeEnvelope',
                params: params,
                returnType: 'void',
            });
        },
    },
    locations: {
        $locations: store_locations_$locations.readable,
        async createNewLocation(params) {
            return await conventionalMethod({
                controllerName: 'locations',
                methodName: 'createNewLocation',
                params: params,
                returnType: 'json',
            });
        },
        async editLocationStyles(params) {
            return await conventionalMethod({
                controllerName: 'locations',
                methodName: 'editLocationStyles',
                params: params,
                returnType: 'void',
            });
        },
        async removeLocation(params) {
            return await conventionalMethod({
                controllerName: 'locations',
                methodName: 'removeLocation',
                params: params,
                returnType: 'void',
            });
        },
        async getDefaultExpenseLocation() {
            return await conventionalMethod({
                controllerName: 'locations',
                methodName: 'getDefaultExpenseLocation',
                params: null,
                returnType: 'json',
            });
        },
    },
};
