<script lang="ts">
</script>

<div class="list">
	<slot />
</div>

<style>
	.list {
		border-radius: 10px;
		background: var(--background2);
	}
</style>
