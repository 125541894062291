export function accountDisplayTitle(name) {
    if (name === '_builtin.tithe')
        return 'Tithe';
    return name;
}
export function accountsSorter(envelopes) {
    return envelopes.sort((a, b) => {
        if (a.name === '_builtin.tithe')
            return 1;
        return 0;
    });
}
export function dateSorter(transactions) {
    return transactions.sort((a, b) => b.date - a.date);
}
